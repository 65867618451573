import {mapActions, mapGetters} from 'vuex';

export default {
	data() {
		return {};
	},
	mounted() {
		this.setCallTrackingAccountInfo();
	},
	methods: {
		...mapActions({
			setCallTrackingAccountInfo: 'others/callTrackingAccountInfo'
		}),
	},
	computed: {
		...mapGetters({
			getCallTrackingAccountInfo: 'others/callTrackingAccountInfo',
		})
	},
	i18n: {
		messages: {
			uk: {
				'Счет звонков': 'Рахунок дзвінків',
				'Пополнить': 'Поповнити',
				'Получайте звонки с отсрочкой оплаты даже когда закончились деньги.': 'Отримуйте дзвінки з відстрочкою оплати навіть коли закінчилися гроші.',
				'В рамках отсрочки вам будет доступно:': 'В рамках відстрочки вам буде доступно:',
				'день': '0 днів | {count} день | {count} дні | {count} днів',
				'На счету недостаточно средств и компания размещается с отсрочкой оплаты.': 'На рахунку недостатньо коштів і компанія розміщується з відстрочкою оплати.',
				'В рамках отсрочки вам было предоставлено': 'В рамках відстрочки вам було надано',
				'Доступный остаток:': 'Доступний залишок:',
				'Размещение приостановлено': 'Розміщення призупинено',
				'Вы исчерпали весь срок': 'Ви вичерпали весь термін',
				'для размещения по системе отсрочки.': 'для розміщення по системі відстрочки.',
				'Чтобы продолжить размещение вашей компании и получать звонки, рекомендуем пополнить бюджет звонков на': 'Щоб продовжити розміщення вашої компанії і отримувати дзвінки, рекомендуємо поповнити бюджет дзвінків на',
				'Вы исчерпали все средства': 'Ви вичерпали всі кошти',
			},
			ru: {
				'Счет звонков': 'Счет звонков',
				'Пополнить': 'Пополнить',
				'Получайте звонки с отсрочкой оплаты даже когда закончились деньги.': 'Получайте звонки с отсрочкой оплаты даже когда закончились деньги.',
				'В рамках отсрочки вам будет доступно:': 'В рамках отсрочки вам будет доступно:',
				'день': '0 дней | {count} день | {count} дня | {count} дней',
				'На счету недостаточно средств и компания размещается с отсрочкой оплаты.': 'На счету недостаточно средств и компания размещается с отсрочкой оплаты.',
				'В рамках отсрочки вам было предоставлено': 'В рамках отсрочки вам было предоставлено',
				'Доступный остаток:': 'Доступный остаток:',
				'Размещение приостановлено': 'Размещение приостановлено',
				'Вы исчерпали весь срок': 'Вы исчерпали весь срок',
				'для размещения по системе отсрочки.': 'для размещения по системе отсрочки.',
				'Чтобы продолжить размещение вашей компании и получать звонки, рекомендуем пополнить бюджет звонков на': 'Чтобы продолжить размещение вашей компании и получать звонки, рекомендуем пополнить бюджет звонков на',
				'Вы исчерпали все средства': 'Вы исчерпали все средства',
			}
		}
	}
};
