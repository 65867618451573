import scrollToTop from '../../../helpers/cabinetScroll';

export default {
	name: 'Pagination',
	data: function () {
		return {
			isTooltipOpened: false,
			withOutScroll: false,
		};
	},
	created() {
	},
	props: ['page', 'pages', 'limit', 'count', 'options', 'type'],
	watch: {},
	mounted() {
		if (this.options) {
			this.withOutScroll = this._result(this.options, 'withOutScroll');
		}
	},
	methods: {
		toggleTooltip() {
			this.isTooltipOpened = !this.isTooltipOpened;
		},
		goto(page) {
			if (page > 0 && page <= this.pages) {
				if (!this.withOutScroll) {
					this.scrollToTop(280);
				}
				this.$emit('onChangePage', page);
			}
		},
		select(limit) {
			if (!this.withOutScroll) {
				this.scrollToTop(280);
			}
			this.$emit('onChangeLimit', limit);
			this.toggleTooltip();
		},
		scrollToTop
	},
	computed: {
		limitText() {
			if (this.type === 'notifications') {
				return this.$t('уведомлений');
			}
			return this.$t('объявлений');
		},
	},
	i18n: {
		messages: {
			uk: {
				'Показывать по': 'Показувати по',
				объявлений: 'оголошень',
				уведомлений: 'сповіщень',
			},
			ru: {
				'Показывать по': 'Показывать по',
				объявлений: 'объявлений',
				уведомлений: 'уведомлений',
			}
		}
	}
};
