import {mapActions, mapGetters} from 'vuex';
import Pagination from '../../../Common/Pagination/index.vue';
const {get, set} = require('../../../../helpers/cookie');
const EXPIRES = 604800;
const START_LIMIT = 10;

export default {
	props: ['brandId', 'modelId'],
	data() {
		return {
			limit: _TARGET_ === 'client' ? (Number(get('cabinet_ipp_auction')) || START_LIMIT) : START_LIMIT,
			skip: 0,
			page: 1,
			advertisementsOnPage: [],
			queryAutoId: undefined,
			queryPopupRate: undefined
		};
	},
	mounted: function () {
		this.queryAutoId = Number(this.$route.query.popup);
		this.queryPopupRate = Number(this.$route.query.popupRate);

		this.fetchAutoIds().then(() => {
			this.fetchAutoData({ids: this.currentPageAutoIds, options: {rateExist: true}});
			if (this.advertisementsIds.indexOf(this.queryAutoId) !== -1) {
				this.$emit('openPopup', this.queryAutoId, this.queryPopupRate);
			}
		});

	},
	components: {
		'pagination': Pagination,
	},
	methods: {
		...mapActions({
			fetchAutoIds: 'cabinet/advertisements/fetch',
			fetchAutoData: 'cabinet/advertisements/advertisement/fetch',

		}),
		getAutos() {
			this.fetchAutoIds({
				nested: [{brandId: Number(this.brandId) ? Number(this.brandId) : undefined, modelId: Number(this.modelId) ? this.modelId : undefined}]
			}).then(() => {
				this.fetchAutoData({ids: this.currentPageAutoIds, options: { rateExist: true }});

			});
		},
		onChangePage(page) {
			this.page = page;
			this.fetchAutoData({ids: this.currentPageAutoIds, options: { rateExist: true }});
		},
		onChangeLimit(limit) {
			this.page = 1;
			this.limit = limit;
			this.fetchAutoData({ids: this.currentPageAutoIds, options: { rateExist: true }});
			set('cabinet_ipp_auction', limit, { expires: EXPIRES });
		},
	},
	computed: {
		...mapGetters({
			advertisementsIds: 'cabinet/advertisements/advertisements',
			info_: 'cabinet/advertisements/advertisement/info',

		}),
		count() {
			return this.advertisementsIds.length;
		},
		pages() {
			return Math.ceil(this.count / this.limit);
		},
		currentPageAutoIds() {
			return this.advertisementsIds.slice(this.limit * this.page - this.limit, this.limit * this.page);
		}
	},
	watch: {
		brandId() {
			this.page = 1;
			if (Number(this.brandId)) {
				this.getAutos();
			} else {
				this.fetchAutoIds().then(() => {
					this.fetchAutoData({ids: this.currentPageAutoIds, options: { rateExist: true }});
				});
			}
		},
		modelId() {
			this.page = 1;
			this.getAutos();
		}
	},
	i18n: {
		messages: {
			uk: {
				'Мои предложения': 'Мої пропозиції',
				'Стоимость звонка': 'Вартість дзвінка',
				'Изменить': 'Змінити',
				'': '',
			},
			ru: {
				'Мои предложения': 'Мои предложения',
				'Стоимость звонка': 'Стоимость звонка',
				'Изменить': 'Изменить',
				'': '',

			}
		}
	}
};
