import CallBudgetBlock from '../CallsJournal/CallBudgetBlock/index.vue';
import AmountItem from './AmountItem/index.vue';
import MyAdvertisement from './MyAdvertisement/index.vue';
import Popup from './Popup/index.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
	data() {
		return {
			brandId: 0,
			modelId: 0,
			period: 7,
			showPopup: false,
			popupAdvertisementId: undefined,
			popupAdvertisementRate: undefined
		};
	},
	components: {
		'calls-budget-block': CallBudgetBlock,
		'amount-item': AmountItem,
		'my-advertisement': MyAdvertisement,
		'popup': Popup
	},
	mounted() {
		this.fetchAuctionPerformance();
	},
	computed: {
		...mapGetters({
			auction_: 'cabinet/auction/auction',
		})
	},
	methods: {
		...mapActions({
			fetchAuctionPerformance: 'cabinet/auction/auction',
		}),
		fetchData() {
			this.fetchAuctionPerformance({
				brandId: this.brandId,
				modelId: this.modelId,
				period: this.period
			});
		},
		closePopup() {
			this.showPopup = false;
		},
		openPopup(id, popupRate) {
			this.showPopup = true;
			this.popupAdvertisementId = id;
			this.popupAdvertisementRate = popupRate;
		}
	},
	watch: {
		brandId() {
			if (!Number(this.brandId)) {
				this.modelId = 0;
			}
			this.fetchData();
		},
		modelId() {
			this.fetchData();
		},
		period() {
			this.fetchData();
		},
	},
	i18n: {
		messages: {
			uk: {
				'Управление аукционом': 'Управління аукціоном',
				'Отчёт об эффективности': 'Звіт про ефективність',
				'В сравнении с предыдущим периодом': 'У порівнянні з попереднім періодом',
				'Все марки': 'Всі марки',
				'Все модели': 'Всі моделі',
				'': '',
			},
			ru: {
				'Управление аукционом': 'Управление аукционом',
				'Отчёт об эффективности': 'Отчёт об эффективности',
				'В сравнении с предыдущим периодом': 'В сравнении с предыдущим периодом',
				'Все марки': 'Все марки',
				'Все модели': 'Все модели',
				'': '',
			}
		}
	}
};
