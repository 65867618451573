import {mapActions, mapGetters} from 'vuex';
import AmountItem from '../AmountItem/index.vue';
import axios from 'axios';

export default {
	props: ['show', 'advertisementId', 'popupRates'],
	data() {
		return {
			startRate: undefined,
			popupRate: undefined,
			stepRate: 10,
			selectPeriod: [7, 30, 90],
			period: 7,
			dropDown: false,
			minRate: 200,
			loader: true
		};
	},
	methods: {
		...mapActions({
			fetchAutoRate: 'cabinet/auction/autoRate',
			openPopup: 'cabinet/advertisements/popups/open',
			setNewRate: 'cabinet/advertisements/advertisement/setNewRate'
		}),
		closePopup() {
			this.$emit('closePopup');
			this.popupRate = this.startRate;
		},
		changeRate(value) {
			this.popupRate = Number(this.popupRate) + value;
		},
		resetPage() {
			this.dropDown = false;
			this.loader = true;
		},
		newRateSubmit() {
			axios({
				method: 'POST',
				url: '/api/call-tracking/public/auction/rate/',
				data: {
					autoId: this.advertisementId,
					rate: this.popupRate
				}
			}).then(() => {
				// change rate & reset popup data
				this.setNewRate({id: this.advertisementId, value: this.popupRate});
				this.fetchAutoRate({advertisementId: this.advertisementId, params: {period: this.period}});
				this.$emit('closePopup');
			}).catch((e) => {
				console.error('/api/call-tracking/public/auction/rate/', e.message);
			});
		}
	},
	components: {
		'amount-item': AmountItem,
	},
	computed: {
		...mapGetters({
			info_: 'cabinet/advertisements/advertisement/info',
			autoRate_: 'cabinet/auction/autoRate',
			popup: 'cabinet/advertisements/popups/popup',

		}),
		autoData() {
			return this.info_(this.advertisementId);
		},
		myRateObject() {
			return this._first(this._result(this.autoRate_, 'rateData').filter(i => i.isMyRate));
		}
	},
	watch: {
		advertisementId() {
			if (this.advertisementId) {
				this.resetPage();
				this.fetchAutoRate({advertisementId: this.advertisementId, params: {period: this.period}}).then(() => {
					this.startRate = this.myRateObject.rate;
					this.popupRate = this.popupRates || this.myRateObject.rate;
					this.loader = false;
				});
			}
		},
		popupRate() {
			if (this.popupRate % 10 !== 0) {
				this.popupRate = Math.round(this.popupRate / 10) * 10;
			}
			if (this.popupRate < this.minRate) {
				this.popupRate = this.minRate;
			}
		},
		period() {
			this.fetchAutoRate({advertisementId: this.advertisementId, params: {period: this.period}});
		}
	},
	i18n: {
		messages: {
			uk: {
				'Изменить стоимость звонка': 'Змінити вартість дзвінка',
				'Статистика объявления': 'Статистика оголошення',
				'Редактировать': 'Редагувати',
				'Эффективность объявления': 'Ефективність оголошення',
				'В сравнении с предыдущим периодом': 'У порівнянні з попереднім періодом',
				'Период': 'Період',
				'последних дней': 'останніх днів',
				'Стоимость звонка на рынке': 'Вартість дзвінка на ринку',
				'Среди предложений по': 'Серед пропозицій щодо',
				'Цена, грн': 'Ціна, грн',
				'Количество предложений': 'Кількість пропозицій',
				'Ваша цена за звонок меньше чем у конкурентов': 'Ваша ціна за дзвінок менше ніж у конкурентів',
				'Чтобы размещаться на высших позициях, поднимите цену за звонок до': 'Щоб розміщуватися на вищих позиціях, підійміть ціну за дзвінок до',
				'Моя ставка за звонок': 'Моя ставка за дзвінок',
				'Применить': 'Застосувати',
				'': '',
			},
			ru: {
				'Изменить стоимость звонка': 'Изменить стоимость звонка',
				'Статистика объявления': 'Статистика объявления',
				'Редактировать': 'Редактировать',
				'Эффективность объявления': 'Эффективность объявления',
				'В сравнении с предыдущим периодом': 'В сравнении с предыдущим периодом',
				'Период': 'Период',
				'последних дней': 'последних дней',
				'Стоимость звонка на рынке': 'Стоимость звонка на рынке',
				'Среди предложений по': 'Среди предложений по',
				'Цена, грн': 'Цена, грн',
				'Количество предложений': 'Количество предложений',
				'Ваша цена за звонок меньше чем у конкурентов': 'Ваша цена за звонок меньше чем у конкурентов',
				'Чтобы размещаться на высших позициях, поднимите цену за звонок до': 'Чтобы размещаться на высших позициях, поднимите цену за звонок до',
				'Моя ставка за звонок': 'Моя ставка за звонок',
				'Применить': 'Применить',
				'': '',
			}
		}
	}
};
